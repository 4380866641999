import { template as template_8e6aa0f1c5e547f48992b08d01c661d1 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_8e6aa0f1c5e547f48992b08d01c661d1(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
